// normalize CSS across browsers
import "./src/normalize.css";
// custom CSS styles
import "./src/style.scss";

// From the firebase app config page:
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCWTdSS_Ljg3SfOol_1qVR1aUDyLeWoO1g",
  authDomain: "rhlconsulting-site.firebaseapp.com",
  projectId: "rhlconsulting-site",
  storageBucket: "rhlconsulting-site.appspot.com",
  messagingSenderId: "395436910088",
  appId: "1:395436910088:web:85dc3093c4cec02e3d53bd",
  measurementId: "G-DVLFWWH7LK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
